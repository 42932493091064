export const SET_PROGRAMMES = 'setProgrammes'
export const SET_PROGRAMME = 'setProgramme'
export const SET_LOADING_PROGRAMMES = 'setLoadingProgrammes'
export const SET_LOADING_LOCAL_POLICIES = 'setLoadingLocalPolicies'
export const SET_LOADING_LOCATIONS = 'setLoadingLocations'
export const SET_LOADED_COVERAGE = 'setLoadedCoverage'
export const SET_LOADING_COVERAGE = 'setLoadingCoverage'
export const SET_ACTIVITY_FEED = 'setActivityFeed'
export const ADD_LOCAL_POLICY = 'addLocalPolicy'
export const ADD_CLAIM = 'addClaim'
export const ADD_COVERAGE_DOCUMENT = 'addCoverageDocument'
export const ADD_LOCATIONS = 'AddLocations'
export const CLEAR_PROGRAMMES = 'clearProgrammes'
export const CLEAR_PROGRAMME = 'clearProgramme'
export const CLEAR_LOCAL_POLICIES = 'clearLocalPolicies'
export const UPDATE_CONTACT = 'updateContact'

export const SET_USER = 'setUser'
export const CLEAR_USER = 'clearUser'
export const SET_SELECT_CLIENT_ID = 'setSelectedClientId'
export const SET_SINGLE_CLIENT = 'setSingleClient'
export const UPDATE_PASSWORD_EXPIRED = 'updatePasswordExpired'

export const UPDATE_TWO_FACTOR_STATUS = 'updateTwoFactorStatus'
export const SET_TWO_FACTOR_METHOD = 'setTwoFactorMethod'
export const ACCEPT_TERMS = 'acceptTerms'
export const SET_IS_JAPAN_BUSINESS = 'setIsJapanBusiness'
export const SORT_LOCAL_POLICY_LOCATIONS = 'sortPolicyLocation'
export const SET_COVERAGE_CURRENCY_TYPE = 'setCoverageCurrencyType'
export const SET_FINANCE_CURRENCY_TYPE = 'setFinanceCurrencyType'
export const SET_LOCATIONS_CURRENCY_TYPE = 'setLocationsCurrencyType'