export const ACTIVITY_FEED_TIMEFRAME = Object.freeze({
    SINCE_LAST_LOGIN: 0,
    IN_LAST_WEEK: 1,
    IN_LAST_MONTH: 2,
});

export const POLICY_PAID_STATUS = Object.freeze({
    UNPAID: 0,
    PAID: 1,
    PARTIAL: 2,
    UNKNOWN: -1,
});
